import { Controller } from "@hotwired/stimulus"
import dayjs from 'dayjs';

export default class extends Controller {
  static targets = ["form", "filter", "collection"]

  connect() {
    this.type = '',
    this.start = '2000-01-01',
    this.end = dayjs().format('YYYY-MM-DD')
  }

  updateType(event) {
    this.type = event.target.value;
    this.change();
  }
  updateStart(event) {
    if (event.target.value == "") {
      this.start = '2000-01-01';
      this.change();
    } else {
      this.start = event.target.value;
      this.change();
    }
  }
  updateEnd(event) {
    if (event.target.value == "") {
      this.end = dayjs().format('YYYY-MM-DD');
      this.change();
    } else {
      this.end = event.target.value;
      this.change();
    }
  }

  change() {
    const url = `${this.formTarget.action}?type=${this.type}&start=${this.start}&end=${this.end}`
    fetch(url, { headers: { 'Accept': 'text/plain' } })
    .then(response => response.text())
    .then((data) => {
      this.collectionTarget.outerHTML = data;
    })
    .then(() => {
      var data = document.getElementById('markers-data');
      var markers = JSON.parse(data.dataset.markers);
      const serializeMarkers = [];
      markers.forEach(marker => {
        serializeMarkers.push(
          {
            start: marker.start,
            content: getContent(marker.date, marker.path, marker.state)
          });
        return serializeMarkers
      });

      var items = new vis.DataSet(serializeMarkers);

      timeline.setItems(items);
      timeline.fit();
    })
  }
}
