import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "list", "searchInput"]

  update() {
    const url = `${this.formTarget.action}?query=${this.searchInputTarget.value}`
    fetch(url, { headers: { 'Accept': 'text/plain' } })
      .then(response => response.text())
      .then((data) => {
        this.listTarget.outerHTML = data;
      })
      .then(() => {
        var areasCollection = document.getElementsByClassName('site-block');
        var areas = Array.prototype.slice.call(areasCollection, 0);
        var areaIds = []
        areas.forEach((area, index) => {
          areaIds.push(area.dataset.areaId);
        });
        var markerCollection = document.getElementsByClassName('mapboxgl-marker');
        Array.prototype.forEach.call(markerCollection, (marker) => {
          if (!areaIds.includes(marker.dataset.markerId)) {
            marker.style.visibility = 'hidden';
          } else {
            marker.style.visibility = 'visible';
          };
        });
      })
  }
}
