import ApplicationController from './application_controller'
export default class extends ApplicationController {

  afterReflex (element) {
    // element.checked = !element.checked;
    document.getElementById('inputToCopy').classList.toggle("is-hidden");
    document.getElementById('previewBtn').classList.toggle("is-hidden");
    document.getElementById('copyButton').classList.toggle("is-hidden");
  }

}
